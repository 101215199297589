<template>
  <div>
    <!-- 头部 -->
    <div class="search">
      <el-row>
        <el-col :span="5">
          <div class="search_input"></div>
        </el-col>
        <el-col :span="5">
          <div class="search_input">
            <!-- <el-button class="btn" type="primary">搜索</el-button> -->
          </div>
        </el-col>
        <el-col :offset="12" :span="1">
          <div class="search_input">
            <el-button
              class="btn"
              icon="el-icon-plus"
              @click="handleAdd('add')"
              type="primary"
              >新增</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <MoTable
      :cols="cols"
      :tableData="tableData"
      @handleEventButton="handleEventButton"
      :operation="operation"
    />
    <!-- 弹窗    -->
    <el-dialog
      width="30%"
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      :before-close="onClose"
    >
      <el-form label-width="80px" ref="Form" :rules="FormRules" :model="Form">
        <el-form-item label="线索类型" prop="name">
          <el-input v-model="Form.name" placeholder="请输入线索类型"></el-input>
        </el-form-item>
        <el-form-item label="积分" prop="integral">
          <el-input v-model="Form.integral" placeholder="请输入积分"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit('Form', title)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MoTable from "../../../components/MoTable/MoTable";
export default {
  name: "typeThread",
  components: {
    MoTable,
  },
  data() {
    return {
      dialogVisible: false,
      input: "",
      cols: [
        { type: "index", label: "序号" },
        { label: "类型名称", prop: "name" },
        // { label: "积分", prop: "integral" },
      ],
      tableData: [],
      operation: {
        del: true,
        edit: true,
      },
      Form: {
        name: "",
        integral: "",
      },
      title: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await this.$get("/clue/clueTypeList");
      if (res.flag) {
        this.tableData = res.data;
      }
    },
    handleEventButton(type, row) {
      console.log(111, type);
      console.log(222, row);
      if (type == "edit") {
        this.title = "编辑";
        this.Form = Object.assign({}, this.Form, row);
        this.dialogVisible = true;
      } else if (type == "del") {
        this.$confirm("确认删除？")
          .then((_) => {
            this.handleDel(row.id);
            done();
          })
          .catch((_) => {});
      }
    },
    handleAdd(val) {
      this.title = "添加";
      this.dialogVisible = true;
    },
    onClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.Form = { name: "", integral: "" };
          this.dialogVisible = false;
          done();
        })
        .catch((_) => {});
    },
    // 提交
    onSubmit(formName, type) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (type == "添加") {
            const res = await this.$post("/clue/addClueType", this.Form);
            if (res.flag) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.$refs[formName].resetFields();
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          } else {
            const res = await this.$put("/clue/updateClueType", this.Form);
            if (res.flag) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.$refs[formName].resetFields();
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          }
        }
      });
    },
    async handleDel(id) {
      const res = await this.$del("/clue/removeClueType/" + id);
      if (res.flag) {
        this.$message.success(res.message);
        this.getList();
      }
    },
  },
  computed: {
    FormRules() {
      return {
        name: [{ required: true, message: "请输入线索类型", trigger: "blur" }],
        integral: [{ required: true, message: "请输入积分", trigger: "blur" }],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  .search_input {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    .btn {
      width: 110px;
      height: 40px;
    }
  }
}
</style>